import { Button } from "@material-ui/core";
import { isFuture, isWithinInterval, sub } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { accountCreate, websocketSet } from "../../core/form/form.action";
import { FormMandatory, Alerts } from "../../core/skin/skin.constants";
import { useStoreState } from "../../core/store/store.hook";
import { validitySet } from "../../core/validity/validity.action";
import { WSProtocol } from "../../core/websocket/websocket.constant";
import { isStrictEmail } from "../../global/string-checks/strict-email";
import {
  isStrictPassword,
  isStrongPassword,
} from "../../global/string-checks/strict-password";
import { isUsernameBlacklisted } from "../../global/string-checks/strict-username";

export const FormSubmit = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const skin = useStoreState((state) => state.skin.skin);
  const rootAgent = skin.rootAgent ?? skin.skin;
  const formData = useStoreState((state) => state.form.formState.form);
  const validity = useStoreState((state) => state.validity.validity);
  const emailTaken = !useStoreState(
    (state) => state.form.formState.uniqueEmail
  );
  const navigation = useStoreState((state) => state.navigation.page);

  const { t } = useTranslation();

  return (
    <Button
      style={{
        marginRight: 5,
        marginBottom: 10,
        WebkitBoxShadow: skin.highlightSubmit
          ? "2px 2px 13px 14px #000000"
          : "inherit",
        boxShadow: skin.highlightSubmit
          ? "2px 2px 13px 14px #000000"
          : "inherit",
        fontFamily: "arial",
        fontWeight: "bold",
      }}
      variant={"outlined"}
      onClick={() => {
        if (navigation === "phoneVerification") {
          dispatch(
            websocketSet({
              type: WSProtocol.SubmitPhoneCode,
              Str1: formData.phoneVerificationCode,
            })
          );
          return;
        }
        let toastText: string[] = [];
        if (!skin.mandatoryFields?.email && !formData.email) {
        }
        if (skin.mandatoryFields?.phoneNumber && !formData.phoneNumber) {
          toastText = [
            ...toastText,
            t(skin.phoneCustomAlert || "alertMissingPhone"),
          ];
        }
        if (validity.phoneTaken) {
          toastText = [...toastText, t("alertTakenPhone")];
        }

        if (skin.mandatoryFields) {
          for (const field in skin.mandatoryFields) {
            const key = field as keyof FormMandatory;
            if (
              skin.mandatoryFields[key] &&
              !formData[key] &&
              key !== "phoneNumber" &&
              key !== "birthDate"
            ) {
              toastText = [...toastText, t("alertMandatoryFields")];
              break;
            }
          }
          for (const alert in skin.alerts) {
            const key = alert as keyof Alerts;
            if (skin.mandatoryFields[key] && !formData[key]) {
              toastText = skin.alerts[key]
                ? [...toastText, t(skin.alerts[key]!)]
                : toastText;
            }
          }
          if (skin.mandatoryFields.name && !formData.name) {
            toastText = [...toastText, t("alertMissingName")];
          }
          if (skin.mandatoryFields.surname && !formData.surname) {
            toastText = [...toastText, t("alertMissingSurname")];
          }
        }
        if (
          !isStrictEmail(formData.email) &&
          (formData.email !== "" || skin.mandatoryFields?.email)
        ) {
          toastText = [...toastText, t("alertEmailInvalid")];
        }
        if (emailTaken) {
          toastText = [...toastText, t("alertEmailTaken")];
        }
        if (formData.username.length < 5) {
          toastText = [...toastText, t("alertUsernameShort")];
        }
        if (validity.usernameTaken) {
          toastText = [...toastText, t("alertUsernameTaken")];
        }
        if (
          skin.phoneMasks &&
          !Object.keys(skin.phoneMasks).find((mask) => {
            return (
              formData.phoneNumber?.substring(1, mask.length + 1) === mask &&
              !!skin.phoneMasks?.[mask].find(
                (length) => length === (formData.phoneNumber?.length ?? 0) - 1
              )
            );
          })
        ) {
          toastText = [...toastText, t("alertPhoneMask")];
        }
        if (
          skin.usernameBlacklist &&
          skin.usernameBlacklistAlert &&
          isUsernameBlacklisted(formData.username, skin.usernameBlacklist)
        ) {
          toastText = [...toastText, t(skin.usernameBlacklistAlert)];
        }
        if (!isStrictPassword(formData.password)) {
          toastText = [...toastText, t("alertInvalidPassword")];
        }
        if (skin.strongPass && !isStrongPassword(formData.password)) {
          toastText = [...toastText, t("alertInvalidStrongPassword")];
        }
        if (formData.password === formData.username) {
          toastText = [...toastText, t("alertPasswordSameAsUsername")];
        }
        if (formData.password !== formData.repeatPassword) {
          toastText = [...toastText, t("alertPasswordRepeat")];
        }
        if (formData.birthDate && isFuture(new Date(formData.birthDate))) {
          toastText = [...toastText, t("alertInvalidBirthDate")];
        }
        if (
          formData.birthDate &&
          isWithinInterval(new Date(formData.birthDate), {
            start: sub(new Date(), { years: 18 }),
            end: new Date(),
          })
        ) {
          toastText = [...toastText, t("alertYoungBirthDate")];
        }
        if (!formData.birthDate && skin.mandatoryFields?.birthDate) {
          toastText = [...toastText, t("alertMissingDate")];
        }
        if (
          skin.mandatoryFields?.governmentId &&
          ((formData?.governmentId?.length ?? 0) >
            (skin.governmentIdLimitTop ?? Infinity) ||
            (formData?.governmentId?.length ?? 0) <
              (skin.governmentIdLimitLow ?? 0))
        ) {
          toastText = [...toastText, t("alertCheckAllFields")];
        }
        if (
          !validity.referralCode &&
          !(formData.referralCode === "" || formData.referralCode === rootAgent)
        ) {
          toastText = [...toastText, t("alertInvalidReferralCode")];
        }
        if (!validity.promoCode && formData.promoCode !== "") {
          toastText = [...toastText, t("alertInvalidPromoCode")];
        }
        if (!formData.promoCode && skin.mandatoryFields?.promoCode) {
          toastText = [...toastText, t("alertMissingPromoCode")];
        }
        if (
          skin.stateForbidden &&
          skin.stateHelperText &&
          skin.stateForbidden?.find(
            (state) => state.toLowerCase() === formData?.state?.toLowerCase()
          )
        ) {
          toastText = [...toastText, t(skin.stateHelperText)];
        }
        if (
          (skin.termsOfService || skin.termsOfServiceCheckbox) &&
          !formData.acceptedTos
        ) {
          toastText = [...toastText, t("acceptTos")];
        }
        if (skin.over18Checkbox && !formData.is18) {
          toastText = [...toastText, t("alertUnder18")];
        }
        if (toastText.length) {
          addToast(
            toastText.map((toast, index) => <p key={index}>{`${toast}\n`}</p>),
            {
              appearance: "warning",
              autoDismiss: true,
            }
          );
          return;
        }
        dispatch(validitySet({ dialog: "loading" }));
        setTimeout(() => {
          validity.dialog === "loading" &&
            dispatch(validitySet({ dialog: "timeout" }));
        }, 15000);
        dispatch(accountCreate());
      }}
    >
      {t("submit")}
    </Button>
  );
};
