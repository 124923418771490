import { from, Observable, of } from "rxjs";
import { dateReviver } from "../date-reviver/date-reviver";
import { HttpError, HttpMethod } from "./http.constant";
import { catchError, mapTo, filter } from "rxjs/operators";
import { Environment } from "../../environment";

export const catchHttpError = <T>(callback: (error: HttpError) => T) => (
  source: Observable<any>
): Observable<T> =>
  source.pipe(
    catchError((error: HttpError) => {
      return of(error).pipe(
        filter(() => error instanceof HttpError),
        mapTo(callback(error))
      );
    })
  );

const httpGeneric = async <T>(
  method: HttpMethod,
  url: string,
  data: any = {}
): Promise<any> => {
  const formDataType = data instanceof FormData;
  const options: RequestInit = {
    method,
    mode: "cors",
    // credentials: "include",
    headers: {
      ...(formDataType ? {} : { "Content-Type": "application/json" }),
    },
    referrer: "no-referrer",
  };

  if (method === HttpMethod.POST || method === HttpMethod.PUT) {
    options.body = formDataType ? data : JSON.stringify(data);
  }

  let response: Response | undefined;
  try {
    response = await fetch(`${Environment.apiUrl}${url}`, options);
  } catch (error) {
    throw new HttpError({
      statusCode: 500,
      message: "ERROR_FETCH_FAILED",
      url,
      method,
    });
  }

  if (!response.ok) {
    let _data: any = {};
    try {
      _data = JSON.parse(await response.text(), dateReviver).data;
    } catch (error) {
      throw new HttpError({
        statusCode: response.status,
        message: "ERROR_UNKNOWN_FORMAT",
        url,
        method,
      });
    }
    throw new HttpError({
      statusCode: response.status,
      message: _data.code,
      info: _data.info,
      url,
      method,
    });
  }
  try {
    const _data = JSON.parse(await response.text());
    return _data;
  } catch (error) {
    return {} as any;
  }
};

function get$<T>(url: string): Observable<T> {
  return from(httpGeneric<T>(HttpMethod.GET, encodeURI(url)));
}

function post$<T>(url: string, data?: any): Observable<T> {
  return from(httpGeneric<T>(HttpMethod.POST, url, data));
}

function put$<T>(url: string, data?: any): Observable<T> {
  return from(httpGeneric<T>(HttpMethod.PUT, url, data));
}

function delete$<T>(url: string): Observable<T> {
  return from(httpGeneric<T>(HttpMethod.DELETE, url));
}

async function ugly<T>(url: string): Promise<any> {
  let response;
  try {
    response = await fetch(url);
  } catch (error) {
    // throw new HttpError({
    //   statusCode: 500,
    //   message: "ERROR_FETCH_FAILED",
    //   url,
    //   method: HttpMethod.GET,
    // });
  }
  if (response) {
    return JSON.parse(await response.text());
  }
  return;
}

function uglyGet$<T>(url: string): Observable<T | undefined> {
  return from(ugly<T>(url));
}

export const http = {
  get$,
  post$,
  put$,
  delete$,
  uglyGet$,
};
