import { Button } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { websocketSet } from "../../core/form/form.action";
import { useStoreState } from "../../core/store/store.hook";
import { validitySet } from "../../core/validity/validity.action";
import { WSProtocol } from "../../core/websocket/websocket.constant";
import { isStrictEmail } from "../../global/string-checks/strict-email";

export const NextStep = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const skin = useStoreState((state) => state.skin.skin);
  const formData = useStoreState((state) => state.form.formState.form);
  const validity = useStoreState((state) => state.validity.validity);
  const emailTaken = !useStoreState(
    (state) => state.form.formState.uniqueEmail
  );

  const { t } = useTranslation();

  return (
    <Button
      style={{
        marginRight: 5,
        marginBottom: 10,
        WebkitBoxShadow: skin.highlightSubmit
          ? "2px 2px 13px 14px #000000"
          : "inherit",
        boxShadow: skin.highlightSubmit
          ? "2px 2px 13px 14px #000000"
          : "inherit",
        fontFamily: "arial",
        fontWeight: "bold",
      }}
      variant={"outlined"}
      onClick={() => {
        console.log(formData.phoneNumber);
        let toastText: string[] = [];
        if (!skin.mandatoryFields?.email && !formData.email) {
          toastText = [...toastText, t("alertMissingEmail")];
        }
        if (!formData.phoneNumber) {
          toastText = [
            ...toastText,
            t(skin.phoneCustomAlert || "alertMissingPhone"),
          ];
        }
        // if (formData.phoneNumber && validity.phoneTaken) {
        //   toastText = [...toastText, t("alertTakenPhone")];
        // }
        if (!formData.code) {
          toastText = [...toastText, t("alertPhoneVerificationCodeField")];
        }

        if (
          !isStrictEmail(formData.email) &&
          (formData.email !== "" || skin.mandatoryFields?.email)
        ) {
          toastText = [...toastText, t("alertEmailInvalid")];
        }
        if (emailTaken) {
          toastText = [...toastText, t("alertEmailTaken")];
        }
        if (
          (skin.termsOfService || skin.termsOfServiceCheckbox) &&
          !formData.acceptedTos
        ) {
          toastText = [...toastText, t("acceptTos")];
        }
        if (skin.over18Checkbox && !formData.is18) {
          toastText = [...toastText, t("alertUnder18")];
        }
        if (toastText.length) {
          addToast(
            toastText.map((toast, index) => <p key={index}>{`${toast}\n`}</p>),
            {
              appearance: "warning",
              autoDismiss: true,
            }
          );
          return;
        }
        dispatch(validitySet({ dialog: "loading" }));
        setTimeout(() => {
          validity.dialog === "loading" &&
            dispatch(validitySet({ dialog: "timeout" }));
        }, 15000);
        dispatch(
          websocketSet({
            type: WSProtocol.SubmitPhoneCode,
            Str1: formData.username,
            Str2: formData.email,
            Str3: formData.phoneNumber,
            Str4: formData.code,
          })
        );
      }}
    >
      {t("submit")}
    </Button>
  );
};
